import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import format from 'date-fns/format';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';

import { translations } from '@/locale';
import { chartOptions } from '@flyblack/common/constants';
import { getRevenue } from '@/services/api/reports';

import useLoad from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';

import OverviewCard from './OverviewCard';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RevenueChartCard = () => {
  const intl = useIntl();

  const [chartData, setChartData] = React.useState<ChartData<'line'>>();
  const { value: revenueReport, loading, error } = useLoad({
    load: getRevenue
  });

  React.useEffect(() => {
    if (revenueReport) {
      setChartData({
        labels: revenueReport.periods.map(({ year, periodOfYear }) => format(new Date(year, periodOfYear - 1), 'LLL')),
        datasets: [
          {
            label: intl.formatMessage({ id: translations.pages.overview.revenueChart.labels.deals }),
            data: revenueReport.emptyLegsRevenue.data,
            borderColor: '#4F3F9E',
            backgroundColor: '#4F3F9E'
          },
          {
            label: intl.formatMessage({ id: translations.pages.overview.revenueChart.labels.shuttles }),
            data: revenueReport.jeyShuttleRevenue.data,
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF'
          }
        ]
      });
    }
  }, [revenueReport]);

  return (
    <OverviewCard
      title={intl.formatMessage({ id: translations.pages.overview.revenueChart.title })}
      className="bg-gradient-to-t from-[#606AC1] to-[#D6A9CD]"
    >
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : error ? (
        <FormattedMessage id={translations.pages.overview.errorValue} />
      ) : (
        chartData && (
          <div className="h-[350px]">
            <Line options={chartOptions} data={chartData} />
          </div>
        )
      )}
    </OverviewCard>
  );
};

export default RevenueChartCard;
